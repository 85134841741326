/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*
* import 'zone.js/dist/zone-error';  // Included with Angular CLI.
*/

export const environment = {
  production: false,
  // apiPath: 'http://localhost:3000/v1',
  apiPath: '',
  // accessSystemPaths: ['/acaconfig', '/acaapi'],
  accessSystemPaths: ['/acaconfig'], // Dev doesn't have /acaapi
  nodesPath: '/acaconfig',
  loginPath: '/auth/v2',
  entitiesPath: '/entities/v2',
  application: 'acaconfig',
  title: 'AcaConfig',
  // ignore token management completely (use it only for local development to skip logins)
  skipLoginValidation: false
};
